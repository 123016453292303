<template>
  <main>
    <div class="content_wrapper">
      <div class="annex">
        <div class="title">
          Voici votre numéro de participation : N°
          <span>{{ number }}</span>
        </div>
        <div class="content">
          Votre inscription a bien été enregistrée.<br /><br />
          Vous recevrez dans quelques instants un email de confirmation.
          <br /><br />
          Si votre participation est conforme aux modalités de l'offre, vous
          recevrez votre remboursement sous 6 à 8 semaines après contrôle de vos
          pièces justificatives.
          <br /><br />
          Toute participation
          <b
            >incomplète, erronée, illisible, transmise hors délai, manifestement
            frauduleuse ou ne respectant pas les modalités</b
          >
          sera considérée comme nulle. Aucune copie ou reproduction ne sera
          acceptée ni prise en compte. Toute reproduction frauduleuse expose le
          participant à des sanctions, notamment pénales. <br /><br />
          <span>À bientôt avec GROHE</span>.
          <br><br>
          <div class="button-build">
            <router-link tag="button" to="/">Revenir vers la page d'accueil</router-link>
           <a class="dl_moda" href="https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/modalites.pdf" target="_blank">Télécharger les modalités de l'offre</a>
         
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "win",
  data: function() {
    return {
      number: undefined,
    };
  },
  methods : {
     openModa() {
       document.location = 'https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/modalites.pdf'
     }
  },
  mounted() {
      this.number =  window.result
  }
};
</script>